/* :root {
    --fontSize: 1rem;
    --mainColor: #12345678;
    --highlightColor: hwb(190, 35%, 20%);
}

html, body {
    width: 100%;
    height: 100%;
}

*/


/* Load this in CSS so you wont see FOUC */
.fp-hide, .fp-hidden, .fp-cloak,
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
[v-cloak],
.ng-hide:not(.ng-hide-animate) {
	display: none !important;
}

.layout-maximum-width {
    max-width: 1280px;
    margin: 0 auto;
}




/* Base64 built with openssl tool*/
.icon-facebook {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjY3cHgiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDY3IDY3IiB3aWR0aD0iNjdweCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHBhdGggZD0iTTI4Ljc2NSw1MC4zMmg2Ljc0NFYzMy45OThoNC40OTlsMC41OTYtNS42MjRoLTUuMDk1ICBsMC4wMDctMi44MTZjMC0xLjQ2NiwwLjE0LTIuMjUzLDIuMjQ0LTIuMjUzaDIuODEyVjE3LjY4aC00LjVjLTUuNDA1LDAtNy4zMDcsMi43MjktNy4zMDcsNy4zMTd2My4zNzdoLTMuMzY5djUuNjI1aDMuMzY5VjUwLjMyeiAgIE0zMyw2NEMxNi40MzIsNjQsMyw1MC41NjksMywzNFMxNi40MzIsNCwzMyw0czMwLDEzLjQzMSwzMCwzMFM0OS41NjgsNjQsMzMsNjR6IiBmaWxsPSIjZmZmZmZmIi8+PC9zdmc+);
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}


.icon-twitter {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjY3cHgiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDY3IDY3IiB3aWR0aD0iNjdweCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHBhdGggZD0iTTM3LjE2NywyMi4yODNjLTIuNjE5LDAuOTUzLTQuMjc0LDMuNDExLTQuMDg2LDYuMTAxICBsMC4wNjMsMS4wMzhsLTEuMDQ4LTAuMTI3Yy0zLjgxMy0wLjQ4Ny03LjE0NS0yLjEzOS05Ljk3NC00LjkxNWwtMS4zODMtMS4zNzdsLTAuMzU2LDEuMDE3Yy0wLjc1NCwyLjI2Ny0wLjI3Miw0LjY2MSwxLjI5OSw2LjI3MSAgYzAuODM4LDAuODksMC42NDksMS4wMTctMC43OTYsMC40ODdjLTAuNTAzLTAuMTY5LTAuOTQzLTAuMjk2LTAuOTg1LTAuMjMzYy0wLjE0NiwwLjE0OSwwLjM1NiwyLjA3NiwwLjc1NCwyLjgzOSAgYzAuNTQ1LDEuMDYsMS42NTUsMi4wOTcsMi44NzEsMi43MTJsMS4wMjcsMC40ODdsLTEuMjE1LDAuMDIxYy0xLjE3MywwLTEuMjE1LDAuMDIxLTEuMDg5LDAuNDY3ICBjMC40MTksMS4zNzcsMi4wNzQsMi44MzksMy45MTgsMy40NzVsMS4yOTksMC40NDRsLTEuMTMxLDAuNjc4Yy0xLjY3NiwwLjk3Ni0zLjY0NiwxLjUyNi01LjYxNiwxLjU2OCAgQzE5Ljc3NSw0My4yNTYsMTksNDMuMzQxLDE5LDQzLjQwNWMwLDAuMjExLDIuNTU3LDEuMzk3LDQuMDQ0LDEuODY0YzQuNDYzLDEuMzc3LDkuNzY1LDAuNzgzLDEzLjc0Ni0xLjU2OCAgYzIuODI5LTEuNjczLDUuNjU3LTUsNi45NzgtOC4yMjFjMC43MTMtMS43MTYsMS40MjUtNC44NTEsMS40MjUtNi4zNTRjMC0wLjk3NSwwLjA2My0xLjEwMiwxLjIzNi0yLjI2NyAgYzAuNjkyLTAuNjc4LDEuMzQxLTEuNDE5LDEuNDY3LTEuNjMxYzAuMjEtMC40MDMsMC4xODgtMC40MDMtMC44OC0wLjA0M2MtMS43ODEsMC42MzYtMi4wMzMsMC41NTEtMS4xNTItMC40MDIgIGMwLjY0OS0wLjY3OCwxLjQyNS0xLjkwNywxLjQyNS0yLjI2N2MwLTAuMDYzLTAuMzE0LDAuMDQyLTAuNjcxLDAuMjMzYy0wLjM3NywwLjIxMi0xLjIxNSwwLjUzLTEuODQ0LDAuNzJsLTEuMTMxLDAuMzYxbC0xLjAyNy0wLjcgIGMtMC41NjYtMC4zODEtMS4zNjEtMC44MDUtMS43ODEtMC45MzJDMzkuNzY2LDIxLjkwMiwzOC4xMzEsMjEuOTQ0LDM3LjE2NywyMi4yODN6IE0zMyw2NEMxNi40MzIsNjQsMyw1MC41NjksMywzNFMxNi40MzIsNCwzMyw0ICBzMzAsMTMuNDMxLDMwLDMwUzQ5LjU2OCw2NCwzMyw2NHoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.icon-instagram {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuMjMzIDUuNDg4Yy0uODQzLS4wMzgtMS4wOTctLjA0Ni0zLjIzMy0uMDQ2cy0yLjM4OS4wMDgtMy4yMzIuMDQ2Yy0yLjE3LjA5OS0zLjE4MSAxLjEyNy0zLjI3OSAzLjI3OS0uMDM5Ljg0NC0uMDQ4IDEuMDk3LS4wNDggMy4yMzNzLjAwOSAyLjM4OS4wNDcgMy4yMzNjLjA5OSAyLjE0OCAxLjEwNiAzLjE4IDMuMjc5IDMuMjc5Ljg0My4wMzggMS4wOTcuMDQ3IDMuMjMzLjA0NyAyLjEzNyAwIDIuMzktLjAwOCAzLjIzMy0uMDQ2IDIuMTctLjA5OSAzLjE4LTEuMTI5IDMuMjc5LTMuMjc5LjAzOC0uODQ0LjA0Ni0xLjA5Ny4wNDYtMy4yMzNzLS4wMDgtMi4zODktLjA0Ni0zLjIzMmMtLjA5OS0yLjE1My0xLjExMS0zLjE4Mi0zLjI3OS0zLjI4MXptLTMuMjMzIDEwLjYyYy0yLjI2OSAwLTQuMTA4LTEuODM5LTQuMTA4LTQuMTA4IDAtMi4yNjkgMS44NC00LjEwOCA0LjEwOC00LjEwOHM0LjEwOCAxLjgzOSA0LjEwOCA0LjEwOGMwIDIuMjY5LTEuODM5IDQuMTA4LTQuMTA4IDQuMTA4em00LjI3MS03LjQxOGMtLjUzIDAtLjk2LS40My0uOTYtLjk2cy40My0uOTYuOTYtLjk2Ljk2LjQzLjk2Ljk2LS40My45Ni0uOTYuOTZ6bS0xLjYwNCAzLjMxYzAgMS40NzMtMS4xOTQgMi42NjctMi42NjcgMi42NjdzLTIuNjY3LTEuMTk0LTIuNjY3LTIuNjY3YzAtMS40NzMgMS4xOTQtMi42NjcgMi42NjctMi42NjdzMi42NjcgMS4xOTQgMi42NjcgMi42Njd6bTQuMzMzLTEyaC0xNGMtMi43NjEgMC01IDIuMjM5LTUgNXYxNGMwIDIuNzYxIDIuMjM5IDUgNSA1aDE0YzIuNzYyIDAgNS0yLjIzOSA1LTV2LTE0YzAtMi43NjEtMi4yMzgtNS01LTV6bS45NTIgMTUuMjk4Yy0uMTMyIDIuOTA5LTEuNzUxIDQuNTIxLTQuNjUzIDQuNjU0LS44NTQuMDM5LTEuMTI2LjA0OC0zLjI5OS4wNDhzLTIuNDQ0LS4wMDktMy4yOTgtLjA0OGMtMi45MDgtLjEzMy00LjUyLTEuNzQ4LTQuNjU0LTQuNjU0LS4wMzktLjg1My0uMDQ4LTEuMTI1LS4wNDgtMy4yOTggMC0yLjE3Mi4wMDktMi40NDUuMDQ4LTMuMjk4LjEzNC0yLjkwOCAxLjc0OC00LjUyMSA0LjY1NC00LjY1My44NTQtLjA0IDEuMTI1LS4wNDkgMy4yOTgtLjA0OXMyLjQ0NS4wMDkgMy4yOTkuMDQ4YzIuOTA4LjEzMyA0LjUyMyAxLjc1MSA0LjY1MyA0LjY1My4wMzkuODU0LjA0OCAxLjEyNy4wNDggMy4yOTkgMCAyLjE3My0uMDA5IDIuNDQ1LS4wNDggMy4yOTh6Ii8+PC9zdmc+);
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.icon-telegram {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxLjQxNDIxOyI+PHBhdGggaWQ9InRlbGVncmFtLTIiIGQ9Ik0yNCwyNGwtMjQsMGwwLC0yNGwyNCwwbDAsMjRabS03Ljc0NCwtNS4xNDhjMC4yMTUsMC4xNTMgMC40OTEsMC4xOTEgMC43MzgsMC4wOTdjMC4yNDYsLTAuMDkzIDAuNDI4LC0wLjMwNCAwLjQ4MywtMC41NmMwLjU3OSwtMi43MjIgMS45ODUsLTkuNjE0IDIuNTEyLC0xMi4wOWMwLjAzOSwtMC4xODcgLTAuMDI3LC0wLjM4MSAtMC4xNzMsLTAuNTA2Yy0wLjE0NywtMC4xMjQgLTAuMzUxLC0wLjE2IC0wLjUzMiwtMC4wOTNjLTIuNzk1LDEuMDM0IC0xMS40MDQsNC4yNjQgLTE0LjkyMyw1LjU2N2MtMC4yMjMsMC4wODIgLTAuMzY4LDAuMjk3IC0wLjM2MSwwLjUzM2MwLjAwOCwwLjIzNSAwLjE2NywwLjQ0IDAuMzk1LDAuNTA5YzEuNTc4LDAuNDcxIDMuNjUsMS4xMjggMy42NSwxLjEyOGMwLDAgMC45NjcsMi45MjQgMS40NzIsNC40MWMwLjA2MywwLjE4NyAwLjIxLDAuMzM0IDAuNDAyLDAuMzg0YzAuMTkzLDAuMDUgMC4zOTcsLTAuMDAyIDAuNTQxLC0wLjEzOGMwLjgxMSwtMC43NjUgMi4wNjQsLTEuOTQ4IDIuMDY0LC0xLjk0OGMwLDAgMi4zODEsMS43NDYgMy43MzIsMi43MDdabS03LjM0LC01Ljc4NGwxLjExOSwzLjY5MmwwLjI0OSwtMi4zMzhjMCwwIDQuMzI0LC0zLjkgNi43OSwtNi4xMjRjMC4wNzIsLTAuMDY1IDAuMDgyLC0wLjE3NCAwLjAyMiwtMC4yNTFjLTAuMDYsLTAuMDc3IC0wLjE2OSwtMC4wOTUgLTAuMjUxLC0wLjA0M2MtMi44NTcsMS44MjUgLTcuOTI5LDUuMDY0IC03LjkyOSw1LjA2NFoiLz48L3N2Zz4=);
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.icon-reddit {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQuNTU4IDE1LjgyN2MuMDk3LjA5Ni4wOTcuMjUzIDAgLjM0OS0uNTMxLjUyOS0xLjM2NS43ODYtMi41NDkuNzg2bC0uMDA5LS4wMDItLjAwOS4wMDJjLTEuMTg1IDAtMi4wMTgtLjI1Ny0yLjU0OS0uNzg2LS4wOTctLjA5Ni0uMDk3LS4yNTMgMC0uMzQ5LjA5Ni0uMDk2LjI1NC0uMDk2LjM1MSAwIC40MzMuNDMxIDEuMTUyLjY0MSAyLjE5OS42NDFsLjAwOS4wMDIuMDA5LS4wMDJjMS4wNDYgMCAxLjc2NS0uMjEgMi4xOTktLjY0MS4wOTUtLjA5Ny4yNTItLjA5Ny4zNDkgMHptLS4xMjYtMy44MTRjLS41ODEgMC0xLjA1NC40NzEtMS4wNTQgMS4wNSAwIC41NzkuNDczIDEuMDQ5IDEuMDU0IDEuMDQ5LjU4MSAwIDEuMDU0LS40NzEgMS4wNTQtMS4wNDkgMC0uNTc5LS40NzMtMS4wNS0xLjA1NC0xLjA1em05LjU2OC0xMi4wMTN2MjRoLTI0di0yNGgyNHptLTQgMTEuODUzYzAtLjk3Mi0uNzk1LTEuNzY0LTEuNzcyLTEuNzY0LS40NzcgMC0uOTA4LjE5MS0xLjIyNy40OTctMS4yMDctLjc5NC0yLjg0LTEuMjk5LTQuNjQ3LTEuMzY0bC45ODktMy4xMTMgMi42NzcuNjI4LS4wMDQuMDM5YzAgLjc5NS42NSAxLjQ0MiAxLjQ0OSAxLjQ0Mi43OTggMCAxLjQ0OC0uNjQ3IDEuNDQ4LTEuNDQyIDAtLjc5NS0uNjUtMS40NDItMS40NDgtMS40NDItLjYxMyAwLTEuMTM2LjM4My0xLjM0Ny45MTlsLTIuODg2LS42NzZjLS4xMjYtLjAzMS0uMjU0LjA0Mi0uMjkzLjE2NmwtMS4xMDMgMy40NzFjLTEuODkyLjAyMy0zLjYwNi41MzItNC44NjcgMS4zNS0uMzE2LS4yOTItLjczNi0uNDc0LTEuMi0uNDc0LS45NzUtLjAwMS0xLjc2OS43OS0xLjc2OSAxLjc2MyAwIC42NDcuMzU1IDEuMjA3Ljg3OCAxLjUxNC0uMDM0LjE4OC0uMDU3LjM3OC0uMDU3LjU3MiAwIDIuNjA3IDMuMjA2IDQuNzI4IDcuMTQ2IDQuNzI4IDMuOTQxIDAgNy4xNDYtMi4xMjEgNy4xNDYtNC43MjggMC0uMTgzLS4wMTktLjM2Mi0uMDUtLjU0LjU1NS0uMjk5LjkzNy0uODc2LjkzNy0xLjU0NnptLTkuMzc0IDEuMjFjMC0uNTc5LS40NzMtMS4wNS0xLjA1NC0xLjA1LS41ODEgMC0xLjA1NS40NzEtMS4wNTUgMS4wNSAwIC41NzkuNDczIDEuMDQ5IDEuMDU1IDEuMDQ5LjU4MS4wMDEgMS4wNTQtLjQ3IDEuMDU0LTEuMDQ5eiIvPjwvc3ZnPg==);
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}



.fp-layout-column-reverse {
    flex-direction: column-reverse;
}





[ui-sref] {
    cursor: pointer;
}






/*
* FP Generic
*/

// *** PADDING ***
.fp-padding {
    padding: 16px;

    @media screen and (max-width: 768px) {
        padding: 12px;
    }


    &-top {
        padding-top: 8px;

        &-0 {
            padding-top: 0 !important;
        }

        &-2 {
            padding-top: 2px;
        }

        &-18 {
            padding-top: 18px;
        }
    }

    &-bottom {
        padding-bottom: 8px;

        // .fp-padding-bottom-20
        &-20 {
            padding-bottom: 20px;
        }
    }

    &-left {
        padding-left: 8px;
    }

    &-right {
        padding-right: 8px;

        &-16 {
            padding-right: 12px;
        }
    }



    &-0 {
        padding: 0 !important;
    }

    &-2 {
        padding: 0 !important;
    }

    &-4 {
        padding: 4px;
    }

    &-6 {
        padding: 6px;
    }

    &-8 {
        padding: 8px;


        // .fp-padding-8-0
        &-0 {
            padding: 8px;

            .fpop-media-layout-xs & {
                padding: 0px;
            }
        }

        // .fp-padding-8-4
        &-4 {
            padding: 8px;

            @media screen and (max-width: 768px) {
                padding: 4px;
            }
        }
    }
}


// *** MARGIN ***
.fp-margin {

    &-top {

        &-0 {
            margin-top: 0 !important;
        }

        &-4 {
            margin-top: 4px;
        }

        &-8 {
            margin-top: 8px;
        }

        &-12 {
            margin-top: 12px;
        }

        &-16 {
            margin-top: 16px;
        }

        &-24 {
            margin-top: 24px;
        }

        &-48 {
            margin-top: 48px
        }
    }

    &-bottom {

        &-0 {
            margin-bottom: 0 !important;
        }


        &-20 {
            margin-bottom: 20px !important;
        }

        &-4 {
            margin-bottom: 4px !important;
        }

        &-8 {
            margin-bottom: 8px !important;
        }

        &-16 {
            margin-bottom: 16px !important;
        }
    }


    &-right {
        &-4 {
            margin-right: 4px !important;
        }
    }


    &-auto-x {
        margin: 0 auto !important;
    }

    &-auto-y {
        margin: auto 0 !important;
    }

    &-auto-all {
        margin: auto !important;
    }


    &-0 {
        margin: 0 !important;
    }
}





.fp-text-align {

    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }

}





.fpop-media-layout-xs {
    .my-xs-no-margins {
        margin: 0px;
    }
    .my-xs-vertical-margins-8 {
        margin: 8px 0px;
    }
}

/* ---Add Padding + Margins--- */
.vertical-padding,
.fp-vertical-padding {
    padding: 8px 0px;
}

.fp-horiz-padding {
    &-20 {
        padding: 0px 20px;
    }
}

.fp-horiz-margins {
    &-10 {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    &-4 {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }
}



.fp-vertical-margins {
    margin: 8px 0px;

    &-24 {
        margin: 24px 0px;
    }

    &-16 {
        margin: 16px 0px;
    }

    &-8 {
        margin: 8px 0px;
    }

    &-4 {
        margin: 4px 0px;
    }

    &-2 {
        margin: 2px 0px;
    }
}

.fp-horizontal-margins {
    margin: 0px 8px;

    &-24 {
        margin: 0px 24px;
    }

    &-16 {
        margin: 0px 16px;
    }

    &-8 {
        margin: 0px 8px;
    }

    &-4 {
        margin: 0px 4px;
    }

    &-2 {
        margin: 0px 2px;
    }
}







.fp-center-text {
    text-align: center;
}





.no-paddings,
.fp-no-paddings {
    padding: 0 !important;
}
.no-margins,
.fp-no-margins {
    margin: 0;
}




.no-margins-top,
.fp-no-margins-top {
    margin-top: 0px;

    md-input-container& {
        margin-top: 0px !important;
    }
}

.no-margins-bottom,
.fp-no-margins-bottom {
    margin-bottom: 0px;
}

.no-padding-top,
.fp-no-padding-top {
    padding-top: 0px;
}

.no-padding-bottom,
.fp-no-padding-bottom {
    padding-bottom: 0 !important;
}

.fp-padding-only-bottom-15 {
    padding: 0 0 15px;
}

.fp-sticker {

    overflow: hidden;
	margin: -40px 0px 0px;
    background: #fbec3f;
    background-image: linear-gradient(45deg, rgba(251, 236, 63, .0) 75%, #f7bb37 95%);
    box-shadow: 0 1px 0px rgba(0,0,0,.15);
    color: rgba(0,0,0,0.87);
    width: 55px;
    height: 55px;
    border-radius: 40px;
    text-align: center;
    transform: rotate(5deg);

    s {
        font-size: 10px;
        line-height: 96%;
    }

    strong {
        font-size: 14px;
        line-height: 96%;
    }
}



/* --- Characters After --- */
.comma-after-label,
.fp-comma-after-label {

    &:after {
        content: ", "
    }
}

.colon-after-label,
.fp-colon-after-label {

    &:after {
        content: ": "
    }
}

.ellipsis-after-text,
.fp-ellipsis-after-text {

    &:after {
        content: "... "
    }
}



// TODO: this is too generic. RENAME!
.label {
    font-size: 14px;
}

.fp-label-color,
.fp-caption-color {
    color: rgba(0,0,0,0.38); //Pulled from Angular Material
}


// TODO: Deprecated: fix all refrences and add class for specific color
.fineprint,
.fp-fineprint {
    color: #888;
    font-size: 12px;
}
.fp-fineprint-smaller {
    color: #888;
    font-size: 11px;
}







.fp-fineprint {
    font-size: 12px;

    &-1 {
        font-size: 12px !important;
    }

    &-2 {
        font-size: 11px !important;
    }

    &-3 {
        font-size: 10px !important;
    }

    &-4 {
        font-size: 9px !important;
    }

    &-5 {
        font-size: 8px !important;
    }
}


.truncate,
.fp-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fp-all-caps,
.fp-uppercase {
    text-transform: uppercase;
}

.fp-lowercase {
    text-transform: lowercase;
}

// NOTE: Shouldn't all of these have "!important" ??
.fp-full-height {
    height: 100%;
}

.fp-full-width {
    width: 100% !important;
}

.fp-light-card {
    background-color: #fff;
}

.fp-force-block {
    display: block !important;
}

.fp-pos-relative {
    position: relative !important;
}

.fp-pos-absolute {
    position: absolute !important;
}

.fp-opaque {
    opacity: 1;
}


.fp-btn-close {
    position: absolute;
    top: 4px;
    right: 4px;
}

// FIXME: Name is too verbose, please replace with a more pithy one
.fp-remove-anchor-underlines {
    a, a& {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.fp-inline-block {
    display: inline-block;
}

.fp-inline {
    display: inline;
}

.fp-border-box {
    box-sizing: border-box;
}

.fp-viz-hidden {
    visibility: hidden;
}

.fp-vertical-align-baseline {
    vertical-align: baseline;
}

.fp-vertical-align-sub {
    vertical-align: sub !important;
}

.fp-anchor-color-inherit {
    a {
        color: inherit;
    }
}

.fp-clickable,
.fp-pointer {
    cursor: pointer;
}

.fp-no-select {
    user-select: none;
}

.fp-no-shadow {
    box-shadow: none !important;
}

// Typeography
// <- Remove stupid name
.fp-light-bolding,
.fp-bold-light {
    font-weight: 500;
}

// <- Remove stupid name
.fp-bolded,
.fp-bold {
    font-weight: bold !important;
}

.fp-italic {
    font-style: italic !important;
}

.fp-no-list {
    list-style: none !important;
    list-style-type: none !important;
    padding: 0 !important;
}



.fp-overflow {
    &-hidden {

        &-x {
            overflow-x: hidden !important;
        }

        &-y {
            overflow-y: hidden !important;
        }
    }
}






// Accounts Dropdown Menu
.account-list-dropdown .selected {
    background-color: rgba(34,166,227,0.22);
}




// Forms
.fpop-form-error-summary {
    margin-top: 0;
    margin-bottom: 2em;
}


// Google Optimize CSS
.async-hide {
    opacity: 0 !important;
}



/* Styles the 'editable' form on 'active'*/
.editable-form-active {
    // background-color: rgba(0,0,0,0.02);
    background-color: rgba(251, 255, 0, 0.25);
    border-radius: 4px;
}





/*
    Common FG / BG Colors for any elements
*/
.fp-fgcolor {
    &-white {
        color: #fff;
    }

    &-black {
        color: #000;
    }

    &-fpblue {
        color: #00aeef;
    }
}

.fp-bgcolor {
    &-white {
        background-color: #fff;
    }

    &-black {
        background-color: #000;
    }

    &-fpblue {
        background-color: #00aeef;
    }

    &-impact-green {
        background-color: #2DD00B;
    }

    &-rose-red {
        background-color: #F1453D;
    }

    &-transparent {
        background-color: transparent !important;
    }
}

.fp-box-hr {
    border-style: solid;

    &-bottom {
        border-width: 0 0 1px 0;
    }

    &-top {
        border-width: 1px 0 0 0;
    }

    &-right {
        border-width: 0 1px 0 0;
    }

    &-left {
        border-width: 0 0 0 1px;
    }

    &-width {
        &-1 {
            border-width: 1px;
        }
    }

    &-round {
        &-4 {
            border-radius: 4px;
        }
    }

    &-white {
        border-color: #fff;
    }

    &-black {
        border-color: #000;
    }

    &-fpblue {
        border-color: #00aeef;
    }

    &-darkgray {
        border-color: #A9A9A9;
    }
}


.fp-text-indent {

    &-1 {
        text-indent: 1em !important;
    }

    &-2 {
        text-indent: 2em !important;
    }

    &-3 {
        text-indent: 3em !important;
    }

    &-4 {
        text-indent: 4em !important;
    }
}





/*
Global colors ( moved from fpop-shop-postcss  1/25/2017 )
*/

.color-swatch {

    &-black {
        background-color: #535051;
    }

    &-white {
        background-color: #fff;
    }

    &-silver {
        background-color: #dddfde;
    }

    &-gray {
        background-color: #99989d;
    }

    &-gold {
        background-color: #f2e3d2;
    }

    &-blue {
        background-color: #535F89;
    }

    &-rose-gold {
        background-color: #FFE4E1; //mistyrose
    }
    &-red {
        background-color: #C0392B;
    }
    &-purple {
        background-color: #8E44AD;
    }
}

.color-font {

    // TODO: rename or remove this
    &-orangered {
        color: #ff4500;
    }
    &-error {
        color: #d50000 !important;
    }
    &-success {
        color: #3fc23f !important;
    }
    &-gray {
        color: #888 !important;
    }
    &-warning {
        color: #ffae42 !important;
    }
    &-red {
        color: #ff0000 !important;
    }

    &-black {
        color: #000000 !important;
    }
}



// TODO: Move these out of this file!!!
/*
    Quick Transitions for Angular
*/
.fade-transition {
    transition: all linear 500ms;
    opacity: 1;
}

.fade-transition.ng-hide {
    opacity: 0;
}

.slide-transition {
    opacity: 1;
    //overflow-y: hidden;
    max-height: 425px;
    /* approximate max height */
}
.slide-transition.ng-hide-add, .slide-transition.ng-hide-remove {
    transition: all ease-in-out 400ms;
}
.slide-transition.ng-hide {
    opacity: 0;
    max-height: 0;
}




/*
    Third Party Brand Colors
*/
.background-paypal {
    //background-color: #00457C !important;
    background: linear-gradient(to right, #00457C, #0079C1);
}

.icon-paypal {
    width: 75px;
    padding-right: 6px;
}

.icon-paypal-large {
    width: 90px;
}

.background-facebook {
    background-color: #3b5998 !important;
}

.background-twitter {
    background-color: #55ACEE !important;
}





/*
    Default image placeholder style (broken images)
*/
.img-broken-placeholder {
    background: repeating-linear-gradient( 45deg, rgba( 255, 255, 0, 0.1), rgba( 255, 255, 0, 0.1 ) 10px, #fff 10px, #fff 20px );
    border-radius: 4px;
}

.vert-scroll-gallery {
    height: 300px;
    overflow-y: auto;
    overflow-x: none;
}

.vert-scroll-gallery .selected {
    background-color: rgba(158, 158, 158, 0.5);
    /* background-color: rgba(0,174,239,1); */

}

.success-message {
    margin: 1em auto 0 auto;
    width: 94%;
}

/*
    Thumbnail Viewer via Canvas
*/
fp-thumb {
    canvas {
        cursor: pointer;
    }
}






/*
    Animations
*/
@keyframes stripes {
  from { background-position: 0 0; }
  to   { background-position: 60px 30px; }
}

/*
    Credit: (crnacura) https://github.com/codrops/DotNavigationStyles
*/
.fp-navdot {
    ul {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style: none;
        cursor: default;
        user-select: none;
        white-space: nowrap;
    }

    li {
        position: relative;
        display: inline-block;
        margin: 0 16px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        box-sizing: border-box;

        a {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            outline: none;
            border: 2px solid rgba( 0, 0, 0, 0.3 );
            border-radius: 50%;
            background-color: #fff;
            background-color: rgba(255, 255, 255, 0.3);
            text-indent: -999em; /* make the text accessible to screen readers */
            cursor: pointer;
            position: absolute;
            box-sizing: border-box;

            &:focus {
                outline: none;
            }
        }
    }
}
.fp-navdot-scaleup {

    li {
        a {
            transition: transform 0.3s ease, background-color 0.3s ease;

            &:hover,
            &:focus {
                background-color: #fff;
            }
        }

        &.selected a {
            border: none;
            background-color: rgb(0, 174, 239);
            border: 1px solid rgba(0, 0, 0, 0.2);
            transform: scale( 1.5 );
        }
    }
}




/*
    .pointing.label {
        position: absolute;

        top: 4px;


        &.left {
            left: 20px;
        }

        &.right {
            right: 20px;
        }

        // &.yellow {
        //     color: #333 !important;
        // }
    }
*/





// Status Indicator Overlay
// http://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox
// NOTE: put this on any inner-scrollable element - see "MY: 'fp-my'" for reference
.ff-overflow-bug-fix {
    min-height: 0;
    min-width: 0;
}

.fpop-status-indicator-popper {
    &.tether-element {
        display: block !important;
    }
}

.fpop-status-indicator-overlay {
    background: rgba( 0, 0, 0, 0.5 );

    &.tether-element,
    &.tether-element:after,
    &.tether-element:before,
    &.tether-element *,
    &.tether-element *:after,
    &.tether-element *:before {
        box-sizing: border-box;
    }

    &.tether-element:before {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: rgba(0,0,0,0.5); /*dim the background*/
    }

    &.tether-element {
        display: block !important;
        visibility: hidden;
        z-index: 200;
    }

    &.tether-element.tether-open {
        display: block;
    }
}








/*
Named Colors
*/
//
// .color-aliceblue {
//     background-color: #f0f8ff;
// }
// .color-antiquewhite {
//     background-color: #faebd7;
// }
// .color-aqua {
//     background-color: #00ffff;
// }
// .color-aquamarine {
//     background-color: #7fffd4;
// }
// .color-azure {
//     background-color: #f0ffff;
// }
// .color-beige {
//     background-color: #f5f5dc;
// }
// .color-bisque {
//     background-color: #ffe4c4;
// }
// .color-black {
//     background-color: #000000;
// }
// .color-blanchedalmond {
//     background-color: #ffebcd;
// }
// .color-blue {
//     background-color: #0000ff;
// }
// .color-blueviolet {
//     background-color: #8a2be2;
// }
// .color-brown {
//     background-color: #a52a2a;
// }
// .color-burlywood {
//     background-color: #deb887;
// }
// .color-cadetblue {
//     background-color: #5f9ea0;
// }
// .color-chartreuse {
//     background-color: #7fff00;
// }
// .color-chocolate {
//     background-color: #d2691e;
// }
// .color-coral {
//     background-color: #ff7f50;
// }
// .color-cornflowerblue {
//     background-color: #6495ed;
// }
// .color-cornsilk {
//     background-color: #fff8dc;
// }
// .color-crimson {
//     background-color: #dc143c;
// }
// .color-cyan {
//     background-color: #00ffff;
// }
// .color-darkblue {
//     background-color: #00008b;
// }
// .color-darkcyan {
//     background-color: #008b8b;
// }
// .color-darkgoldenrod {
//     background-color: #b8860b;
// }
// .color-darkgray {
//     background-color: #a9a9a9;
// }
// .color-darkgrey {
//     background-color: #a9a9a9;
// }
// .color-darkgreen {
//     background-color: #006400;
// }
// .color-darkkhaki {
//     background-color: #bdb76b;
// }
// .color-darkmagenta {
//     background-color: #8b008b;
// }
// .color-darkolivegreen {
//     background-color: #556b2f;
// }
// .color-darkorange {
//     background-color: #ff8c00;
// }
// .color-darkorchid {
//     background-color: #9932cc;
// }
// .color-darkred {
//     background-color: #8b0000;
// }
// .color-darksalmon {
//     background-color: #e9967a;
// }
// .color-darkseagreen {
//     background-color: #8fbc8f;
// }
// .color-darkslateblue {
//     background-color: #483d8b;
// }
// .color-darkslategray {
//     background-color: #2f4f4f;
// }
// .color-darkslategrey {
//     background-color: #2f4f4f;
// }
// .color-darkturquoise {
//     background-color: #00ced1;
// }
// .color-darkviolet {
//     background-color: #9400d3;
// }
// .color-deeppink {
//     background-color: #ff1493;
// }
// .color-deepskyblue {
//     background-color: #00bfff;
// }
// .color-dimgray {
//     background-color: #696969;
// }
// .color-dimgrey {
//     background-color: #696969;
// }
// .color-dodgerblue {
//     background-color: #1e90ff;
// }
// .color-firebrick {
//     background-color: #b22222;
// }
// .color-floralwhite {
//     background-color: #fffaf0;
// }
// .color-forestgreen {
//     background-color: #228b22;
// }
// .color-fuchsia {
//     background-color: #ff00ff;
// }
// .color-gainsboro {
//     background-color: #dcdcdc;
// }
// .color-ghostwhite {
//     background-color: #f8f8ff;
// }
// .color-gold {
//     background-color: #ffd700;
// }
// .color-goldenrod {
//     background-color: #daa520;
// }
// .color-gray {
//     background-color: #808080;
// }
// .color-grey {
//     background-color: #808080;
// }
// .color-green {
//     background-color: #008000;
// }
// .color-greenyellow {
//     background-color: #adff2f;
// }
// .color-honeydew {
//     background-color: #f0fff0;
// }
// .color-hotpink {
//     background-color: #ff69b4;
// }
// .color-indianred  {
//     background-color: #cd5c5c;
// }
// .color-indigo  {
//     background-color: #4b0082;
// }
// .color-ivory {
//     background-color: #fffff0;
// }
// .color-khaki {
//     background-color: #f0e68c;
// }
// .color-lavender {
//     background-color: #e6e6fa;
// }
// .color-lavenderblush {
//     background-color: #fff0f5;
// }
// .color-lawngreen {
//     background-color: #7cfc00;
// }
// .color-lemonchiffon {
//     background-color: #fffacd;
// }
// .color-lightblue {
//     background-color: #add8e6;
// }
// .color-lightcoral {
//     background-color: #f08080;
// }
// .color-lightcyan {
//     background-color: #e0ffff;
// }
// .color-lightgoldenrodyellow {
//     background-color: #fafad2;
// }
// .color-lightgray {
//     background-color: #d3d3d3;
// }
// .color-lightgrey {
//     background-color: #d3d3d3;
// }
// .color-lightgreen {
//     background-color: #90ee90;
// }
// .color-lightpink {
//     background-color: #ffb6c1;
// }
// .color-lightsalmon {
//     background-color: #ffa07a;
// }
// .color-lightseagreen {
//     background-color: #20b2aa;
// }
// .color-lightskyblue {
//     background-color: #87cefa;
// }
// .color-lightslategray {
//     background-color: #778899;
// }
// .color-lightslategrey {
//     background-color: #778899;
// }
// .color-lightsteelblue {
//     background-color: #b0c4de;
// }
// .color-lightyellow {
//     background-color: #ffffe0;
// }
// .color-lime {
//     background-color: #00ff00;
// }
// .color-limegreen {
//     background-color: #32cd32;
// }
// .color-linen {
//     background-color: #faf0e6;
// }
// .color-magenta {
//     background-color: #ff00ff;
// }
// .color-maroon {
//     background-color: #800000;
// }
// .color-mediumaquamarine {
//     background-color: #66cdaa;
// }
// .color-mediumblue {
//     background-color: #0000cd;
// }
// .color-mediumorchid {
//     background-color: #ba55d3;
// }
// .color-mediumpurple {
//     background-color: #9370d8;
// }
// .color-mediumseagreen {
//     background-color: #3cb371;
// }
// .color-mediumslateblue {
//     background-color: #7b68ee;
// }
// .color-mediumspringgreen {
//     background-color: #00fa9a;
// }
// .color-mediumturquoise {
//     background-color: #48d1cc;
// }
// .color-mediumvioletred {
//     background-color: #c71585;
// }
// .color-midnightblue {
//     background-color: #191970;
// }
// .color-mintcream {
//     background-color: #f5fffa;
// }
// .color-mistyrose {
//     background-color: #ffe4e1;
// }
// .color-moccasin {
//     background-color: #ffe4b5;
// }
// .color-navajowhite {
//     background-color: #ffdead;
// }
// .color-navy {
//     background-color: #000080;
// }
// .color-oldlace {
//     background-color: #fdf5e6;
// }
// .color-olive {
//     background-color: #808000;
// }
// .color-olivedrab {
//     background-color: #6b8e23;
// }
// .color-orange {
//     background-color: #ffa500;
// }
// .color-orangered {
//     background-color: #ff4500;
// }
// .color-orchid {
//     background-color: #da70d6;
// }
// .color-palegoldenrod {
//     background-color: #eee8aa;
// }
// .color-palegreen {
//     background-color: #98fb98;
// }
// .color-paleturquoise {
//     background-color: #afeeee;
// }
// .color-palevioletred {
//     background-color: #d87093;
// }
// .color-papayawhip {
//     background-color: #ffefd5;
// }
// .color-peachpuff {
//     background-color: #ffdab9;
// }
// .color-peru {
//     background-color: #cd853f;
// }
// .color-pink {
//     background-color: #ffc0cb;
// }
// .color-plum {
//     background-color: #dda0dd;
// }
// .color-powderblue {
//     background-color: #b0e0e6;
// }
// .color-purple {
//     background-color: #800080;
// }
// .color-red {
//     background-color: #ff0000;
// }
// .color-rosybrown {
//     background-color: #bc8f8f;
// }
// .color-royalblue {
//     background-color: #4169e1;
// }
// .color-saddlebrown {
//     background-color: #8b4513;
// }
// .color-salmon {
//     background-color: #fa8072;
// }
// .color-sandybrown {
//     background-color: #f4a460;
// }
// .color-seagreen {
//     background-color: #2e8b57;
// }
// .color-seashell {
//     background-color: #fff5ee;
// }
// .color-sienna {
//     background-color: #a0522d;
// }
// .color-silver {
//     background-color: #c0c0c0;
// }
// .color-skyblue {
//     background-color: #87ceeb;
// }
// .color-slateblue {
//     background-color: #6a5acd;
// }
// .color-slategray {
//     background-color: #708090;
// }
// .color-slategrey {
//     background-color: #708090;
// }
// .color-snow {
//     background-color: #fffafa;
// }
// .color-springgreen {
//     background-color: #00ff7f;
// }
// .color-steelblue {
//     background-color: #4682b4;
// }
// .color-tan {
//     background-color: #d2b48c;
// }
// .color-teal {
//     background-color: #008080;
// }
// .color-thistle {
//     background-color: #d8bfd8;
// }
// .color-tomato {
//     background-color: #ff6347;
// }
// .color-turquoise {
//     background-color: #40e0d0;
// }
// .color-violet {
//     background-color: #ee82ee;
// }
// .color-wheat {
//     background-color: #f5deb3;
// }
// .color-white {
//     background-color: #ffffff;
// }
// .color-whitesmoke {
//     background-color: #f5f5f5;
// }
// .color-yellow {
//     background-color: #ffff00;
// }
// .color-yellowgreen {
//     background-color: #9acd32;
// }








